import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const UploadWidget = (props) => {
    const cloudinaryRef = useRef();
    const widgetRef = useRef();

    useEffect(() => {
        cloudinaryRef.current = window.cloudinary;
        widgetRef.current = cloudinaryRef.current.createUploadWidget({
            cloudName: "healthblocks",
            uploadPreset: 'hbuploadpreset'
        }, function (error, result) {
            if(result.info) {
                if(result.info.files) {
                    if(result.info.files.length > 0) {
                        var uploadInfo = result.info.files[0].uploadInfo;
                        console.log("File Uploaded To " + uploadInfo.secure_url);
                        props.setImageUrl(uploadInfo.secure_url);
                        console.log("Call to parent update completed");
                    }
                }
            }
            console.log(result);
        })
    }, []);

    return (
        <div class="sm:col-span-6 bg-white text-black mt-4">
            <Link to="#" onClick={() => widgetRef.current.open()} class="mt-2 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Change Image</Link>
        </div>
    );

}

export default UploadWidget;