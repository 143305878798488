import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { Link } from 'react-router-dom';

const AccountList = () => {
    const [accountList, setAccountList] = useState([]);

    const fetchAccount = async () => {
        //const response = await HealthblocksApi.get('activity-pool-api/data/me?activity_type=STEPS&aggregate_by_month=false&aggregate_by_year=false');
        setAccountList([]);
    };

    useEffect(() => {
        fetchAccount();
    }, []);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{accountList.length} Account Records</h1>
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Date</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Description</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Debit</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Credit</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {accountList
                                    .map(accountItem => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{accountItem.date}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{accountItem.description}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{accountItem.debit}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{accountItem.credit}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountList;