import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { Link } from 'react-router-dom';

const ActivityPoolRegistrations = (props) => {
    const [apRegistrations, setApRegistrations] = useState([]);
    const activityPoolId = props.activityPoolId;

    const fetchAPRegistrations = async () => {
        const response = await HealthblocksApi.get(`activity-pool-api/registrations/?activity_pool_id=${activityPoolId}`);
        setApRegistrations(response.data.data);
    };

    const deleteAPRegistration = async (id) => {
        await HealthblocksApi.delete(`/activity-pool-api/registrations/${id}`);
        fetchAPRegistrations();
    };

    useEffect(() => {
        fetchAPRegistrations();
    }, []);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{apRegistrations.length} Pool Registrations</h1>
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">User Id</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Activity Pool Id</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {apRegistrations
                                    .map(APRegistration => (
                                        <tr key={APRegistration.APRegistrationCode} class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{APRegistration.userId}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{APRegistration.activityPoolId}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{APRegistration.status}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityPoolRegistrations;