import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const CurrencyForm = () => {
    const { currencyId } = useParams();
    const navigate = useNavigate();
    const [currency, setCurrency] = useState({
        currencyCode: '',
        currencyName: '',
        currencyTypeId: '',
        blockchainTokenId: '',
        isActive: true
    });

    useEffect(() => {
        if (currencyId) {
            const fetchCurrency = async () => {
                const response = await HealthblocksApi.get(`currency-api/currency/${currencyId}`);
                setCurrency(response.data);
            };
            fetchCurrency();
        }
    }, [currencyId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrency({ ...currency, [name]: value });
    };

    const deleteCurrency = async (currencyId) => {
        await HealthblocksApi.delete(`/currency-api/currency/${currencyId}`);
        navigate("/currencies");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (currencyId) {
            await HealthblocksApi.put(`currency-api/currency/${currencyId}`, currency);
        } else {
            await HealthblocksApi.post('currency-api/currency/', currency);
        }
        navigate('/currencies');
    };

    return (
        <div>
            <form onSubmit={handleSubmit} class="mt-4">
                <div class="space-y-12">
                    <div class="border-b border-gray-900/10 pb-12">
                        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                            <div class="sm:col-span-6 bg-black text-white px-2">
                                <h2 class="text-base font-semibold leading-7">Currency Details - ({currency.currencyCode === "" ? "New" : currency.currencyCode})</h2>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="currencyCode" class="block text-sm font-medium leading-6 text-gray-900">Currency Code</label>
                                <div class="mt-2">
                                    <input type="text" name="currencyCode" value={currency.currencyCode} onChange={handleChange} placeholder="Currency Code e.g. EUR" required id="currencyCode" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="currencyName" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                                <div class="mt-2">
                                    <input name="currencyName" value={currency.currencyName} onChange={handleChange} placeholder="Currency Name" required id="currencyName" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="currencyTypeId" class="block text-sm font-medium leading-6 text-gray-900">Currency Type</label>
                                <div class="mt-2">
                                    <select id="currencyTypeId" name="currencyTypeId" value={currency.currencyTypeId} onChange={handleChange} placeholder="Currency Type" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                        <option></option>
                                        <option>FIAT</option>
                                        <option>WEB3</option>
                                    </select>
                                </div>
                            </div>

                            <div class="sm:col-span-3">
                                <label for="blockchainTokenId" class="block text-sm font-medium leading-6 text-gray-900">Blockchain Id</label>
                                <div class="mt-2">
                                    <input name="blockchainTokenId" value={currency.blockchainTokenId} onChange={handleChange} placeholder="Blockchain Token Id" id="blockchainTokenId" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="mt-6 flex items-center justify-end gap-x-6">
                    <Link to="/currencies" class="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                    <Link to="#" onClick={() => deleteCurrency(currency.currencyCode)} class="text-sm font-semibold leading-6 text-gray-900">Delete</Link>
                    <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                </div>
            </form>
        </div>
    );
};

export default CurrencyForm;