import axios from "axios";
import { getAccessToken } from '@privy-io/react-auth';

const HealthblocksApi = axios.create({
 baseURL: process.env.REACT_APP_BASE_URL || "https://api.healthblocks.ai/",
});

HealthblocksApi.interceptors.response.use(
    (response) => {
      console.log(response.data)
      // Transform data here if needed
      return response;
    },
    async (error) => {
      // Do something with response error
      return Promise.reject(error);
    }
  );

  HealthblocksApi.interceptors.request.use(
    async (config) => {
      const token = await getAccessToken(); // Custom function which fetches active token.
      if (token) {
         config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default HealthblocksApi;