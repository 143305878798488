import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { useNavigate } from 'react-router-dom';
import { Cloudinary } from '@cloudinary/url-gen/index';
import { AdvancedImage } from '@cloudinary/react';
import { fill } from "@cloudinary/url-gen/actions/resize";
import UploadWidget from './UploadWidget';


const ImageHolder = (props) => {
    const navigate = useNavigate();

    // Create a Cloudinary instance and set your cloud name.
    const cld = new Cloudinary({
        cloud: {
            cloudName: 'healthblocks'
        }
    });

    // Instantiate a CloudinaryImage object for the image with the public ID, 'docs/models'.
    const myImage = cld.image('activitypools/outdooradventurebackpacker');

    // Resize to 250 x 250 pixels using the 'fill' crop mode.
    myImage.resize(fill().width(250).height(250));

    useEffect(() => {

    }, []);

    const setImageUrl = (url) => {
        console.log("ImageHolder set image Url " + url);
        props.setImageUrl(url);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
    };

    return (
        <div>
            <h1 class="mb-2 font-bold">{props.imagetitle}</h1>
            {
                /*
            <AdvancedImage cldImg={myImage} />
                */
            }
            {props.imageUrl.length > 0
                ?
                <div>
                    <img class="mb-2" src={props.imageUrl} alt={props.imagetitle} ></img>
                    <UploadWidget setImageUrl={setImageUrl} />
                    <h3 class="mt-2 text-xs">Image Url: {props.imageUrl}</h3>
                </div>
                :
                <UploadWidget setImageUrl={setImageUrl} />
            }
        </div>

    );
};

export default ImageHolder;