import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import HealthDataList from './HealthData';
import AccountList from './AccountList';
import HealthDataDiagnosticsList from './HealthDataDiagnosticsList';

const UserForm = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [tabIndex, setTabIndex] = useState(0);
    const [user, setUser] = useState({
        email: '',
        phone: '',
        signUpDate: '',
        role: '',
        id: ''
    });

    const [userProfile, setUserProfile] = useState({
        userId: 'NO-USER-PROFILE'
    });

    useEffect(() => {
        if (userId) {
            const fetchUser = async () => {
                const response = await HealthblocksApi.get(`/user-api/users/${userId}`);
                setUser(response.data);
            };
            fetchUser();
        }
        else {
            const current = new Date();
            const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            user.signUpDate = date;
        }
    }, [userId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    };

    return (
        <div>
            <div>
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select a tab</label>
                    <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option>User Details</option>
                        <option>Account</option>
                        <option>Health Data</option>
                        <option>Health Data Diagnostics</option>
                    </select>
                </div>
                <div class="hidden sm:block">
                    <div class="border-b border-gray-200">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            <Link onClick={() => setTabIndex(0)} to="#userdetails" class={tabIndex === 0 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>User Details</Link>
                            <Link onClick={() => setTabIndex(1)} to="#account" class={tabIndex === 1 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Account</Link>
                            <Link onClick={() => setTabIndex(2)} to="#healthdata" class={tabIndex === 2 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Health Data</Link>
                            <Link onClick={() => setTabIndex(3)} to="#diagnostics" class={tabIndex === 3 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Diagnostics</Link>
                        </nav>
                    </div>
                </div>
            </div>
            <section id="content">
                {tabIndex == 0
                    ?
                    <section id="userDetailsTab">
                        <div class="mt-4">
                            <div class="space-y-12">
                                <div class="border-b border-gray-900/10 pb-12">
                                    <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                        <div class="sm:col-span-6 bg-black text-white px-2">
                                            <h2 class="text-base font-semibold leading-7">User Details ({user.id}) - Created {user.signUpDate}</h2>
                                        </div>

                                        <div class="sm:col-span-3">
                                            <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                                            <div class="mt-2">
                                                <input type="text" name="email" value={user.email} onChange={handleChange} placeholder="Email" required id="first-name" autocomplete="given-name" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>

                                        <div class="sm:col-span-3">
                                            <label for="phone" class="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                                            <div class="mt-2">
                                                <input name="phone" value={user.phone} onChange={handleChange} placeholder="Phone" id="phone" autocomplete="given-name" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                            </div>
                                        </div>
                                        <div class="sm:col-span-3">
                                            <label for="role" class="block text-sm font-medium leading-6 text-gray-900">Role</label>
                                            <div class="mt-2">
                                                <select id="role" name="role" value={user.role} onChange={handleChange} placeholder="role" required class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                    <option>USER</option>
                                                    <option>ADMIN</option>
                                                    <option>SPONSOR</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="mt-6 flex items-center justify-end gap-x-6">
                                <Link to="/users" class="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                                <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                            </div>
                        </div>
                        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow mt-4">
                            <div class="sm:col-span-6 bg-black text-white px-2">
                                <h2 class="text-base font-semibold leading-7">User Profile</h2>
                            </div>
                            <div class="px-4 py-5 sm:p-6">
                                {userProfile.userId === "NO-USER-PROFILE"
                                    ? "No matching user profile exists"
                                    :
                                    <Link class="text-indigo-600 hover:text-indigo-900" to={`/userprofiles/${userProfile.userId}`}>View User Profile</Link>
                                }
                            </div>
                        </div>
                    </section>
                    :
                    tabIndex == 1
                    ?
                    <AccountList /> 
                    :
                    tabIndex === 2
                    ?
                    <HealthDataList userId={user.id} />
                    :
                    <HealthDataDiagnosticsList userId={user.id}/>
                }
            </section>



        </div>

    );
};

export default UserForm;