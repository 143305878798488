import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ImageHolder from './ImageHolder';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import ActivityPoolList from './ActivityPoolList';
import AccountList from './AccountList';

const APSponsorForm = () => {
    const { sponsorId } = useParams();
    const navigate = useNavigate();
    const [currencies, setCurrencies] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const { hash } = useLocation();

    const [apSponsor, setApSponsor] = useState({
        name: '',
        description: '',
        contactName: '',
        contactEmail: '',
        websiteUrl: '',
        twitterAddress: '',
        tikTokAddress: '',
        facebookAddress: '',
        iconUrl: '',
        defaultCurrencyCode: '',
        isActive: true,
        sponsorId: '',
        backgroundImageUrl: '',
        listImageUrl: ''
    });

    useEffect(() => {
        if (sponsorId) {
            const fetchapSponsor = async () => {
                const response = await HealthblocksApi.get(`/activity-pool-sponsor-api/activity-pool-sponsor/${sponsorId}`);
                setApSponsor(response.data);
            };
            fetchapSponsor();
        }
        fetchSettings();
    }, [sponsorId]);

    useEffect(() => {
        if(hash == "#activitypools") setTabIndex(1);
        else if(hash == "#account") setTabIndex(2);
        else setTabIndex(0);
    }, [window.location.hash]);



    const fetchSettings = async () => {
        const currencyResponse = await HealthblocksApi.get(`currency-api/currency/`);
        setCurrencies(currencyResponse.data.data);
    }

    const setIconImageUrl = (url) => {
        setApSponsor(prevState => ({
            ...prevState,
            ["iconUrl"]: url
        }));
    }

    const setBackgroundImageUrl = (url) => {
        setApSponsor(prevState => ({
            ...prevState,
            ["backgroundImageUrl"]: url
        }));
    }

    const setListImageUrl = (url) => {
        setApSponsor(prevState => ({
            ...prevState,
            ["listImageUrl"]: url
        }));
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setApSponsor({ ...apSponsor, [name]: value });
    };

    const deleteapSponsor = async (sponsorId) => {
        await HealthblocksApi.delete(`/activity-pool-sponsor-api/activity-pool-sponsor/${sponsorId}`);
        navigate("/apsponsors");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (sponsorId) {
                await HealthblocksApi.put(`activity-pool-sponsor-api/activity-pool-sponsor/${sponsorId}`, apSponsor);
            } else {
                //Generate a Sponsor Unique ID becaue the API does not do this!
                apSponsor.sponsorId = uuidv4();
                //Create a Sponsor User Id - Payments are associated with a user
                var sponsorUser = {
                    "email": apSponsor.contactEmail,
                    "phone": "",
                    "role": "USER",
                    "signUpDate": moment().format('YYYY-MM-DD')
                };
                var apSponsorUser = await HealthblocksApi.put(`/user-api/users/${apSponsor.sponsorId}`, sponsorUser);
                await HealthblocksApi.put(`activity-pool-sponsor-api/activity-pool-sponsor/${apSponsor.sponsorId}`, apSponsor);
            }
            navigate('/apsponsors');
        }
        catch (err) {
            console.log(err);
        }
    };

    return (
        <div>
            <div>
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select a tab</label>
                    <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option>Sponsor Details</option>
                        <option>Activity Pools</option>
                        <option>Account</option>
                    </select>
                </div>
                <div class="hidden sm:block">
                    <div class="border-b border-gray-200">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            <Link onClick={() => setTabIndex(0)} to="#sponsordetails" class={tabIndex == 0 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Sponsor Details</Link>
                            <Link onClick={() => setTabIndex(1)} to="#activitypools" class={tabIndex == 1 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Activity Pools</Link>
                            <Link onClick={() => setTabIndex(2)} to="#account" class={tabIndex == 2 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Account</Link>
                        </nav>
                    </div>
                </div>
            </div>
            <section id="content">
                {tabIndex == 0
                    ?
                    <form onSubmit={handleSubmit} class="mt-4">
                        <div class="space-y-12">
                            <div class="border-b border-gray-900/10 pb-12">
                                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div class="sm:col-span-3">
                                        <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                                        <div class="mt-2">
                                            <input type="text" name="name" value={apSponsor.name} onChange={handleChange} placeholder="Name" required id="name" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                                        <div class="mt-2">
                                            <textarea name="description" value={apSponsor.description} rows="5" onChange={handleChange} placeholder="Description" required id="description" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="contactName" class="block text-sm font-medium leading-6 text-gray-900">Contact Name</label>
                                        <div class="mt-2">
                                            <input name="contactName" value={apSponsor.contactName} onChange={handleChange} placeholder="Contact Name" required id="contactName" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="contactEmail" class="block text-sm font-medium leading-6 text-gray-900">Contact Email</label>
                                        <div class="mt-2">
                                            <input type="email" name="contactEmail" value={apSponsor.contactEmail} onChange={handleChange} placeholder="Email" required id="contactEmail" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="websiteUrl" class="block text-sm font-medium leading-6 text-gray-900">Website Url</label>
                                        <div class="mt-2">
                                            <input name="websiteUrl" value={apSponsor.websiteUrl} onChange={handleChange} placeholder="Website Url" required id="websiteUrl" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="twitterAddress" class="block text-sm font-medium leading-6 text-gray-900">Twitter Address</label>
                                        <div class="mt-2">
                                            <input name="twitterAddress" value={apSponsor.twitterAddress} onChange={handleChange} placeholder="Twitter Address" id="twitterAddress" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="tikTokAddress" class="block text-sm font-medium leading-6 text-gray-900">TikTok Address</label>
                                        <div class="mt-2">
                                            <input name="tikTokAddress" value={apSponsor.tikTokAddress} onChange={handleChange} placeholder="TikTok Address" id="tikTokAddress" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="facebookAddress" class="block text-sm font-medium leading-6 text-gray-900">Facebook Address</label>
                                        <div class="mt-2">
                                            <input name="facebookAddress" value={apSponsor.facebookAddress} onChange={handleChange} placeholder="Twitter Address" id="facebookAddress" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="instagramAddress" class="block text-sm font-medium leading-6 text-gray-900">Instagram Address</label>
                                        <div class="mt-2">
                                            <input name="instagramAddress" value={apSponsor.instagramAddress} onChange={handleChange} placeholder="Instagram Address" id="instagramAddress" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="telegramAddress" class="block text-sm font-medium leading-6 text-gray-900">Telegram Address</label>
                                        <div class="mt-2">
                                            <input name="telegramAddress" value={apSponsor.telegramAddress} onChange={handleChange} placeholder="Telegram Address" id="telegramAddress" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="discordAddress" class="block text-sm font-medium leading-6 text-gray-900">Discord Address</label>
                                        <div class="mt-2">
                                            <input name="discordAddress" value={apSponsor.discordAddress} onChange={handleChange} placeholder="Discord Address" id="discordAddress" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-2">
                                        <label for="defaultCurrencyCode" class="block text-sm font-medium leading-6 text-gray-900">Default Currency Code</label>
                                        <div class="mt-2">
                                            <select id="defaultCurrencyCode" name="defaultCurrencyCode" value={apSponsor.defaultCurrencyCode} required onChange={handleChange} placeholder="Currency" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option></option>
                                                {currencies
                                                    .map(currency => (
                                                        <option>{currency.currencyCode}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <ImageHolder imagetitle="Background Image" setImageUrl={setBackgroundImageUrl} imageUrl={apSponsor.backgroundImageUrl} />
                                    </div>

                                    <div class="sm:col-span-3">
                                        <ImageHolder imagetitle="Icon" setImageUrl={setIconImageUrl} imageUrl={apSponsor.iconUrl} />
                                    </div>

                                    <div class="sm:col-span-3">
                                        <ImageHolder imagetitle="List Icon" setImageUrl={setListImageUrl} imageUrl={apSponsor.listImageUrl} />
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="mt-6 flex items-center justify-end gap-x-6">
                            <Link to="/apsponsors" class="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                            <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                        </div>
                    </form>
                    :
                    tabIndex == 1
                    ?
                    <section id="activityPoolList">
                        <ActivityPoolList sponsor={apSponsor} />
                    </section>
                    :
                    <section id="activityPoolList">
                        <AccountList sponsor={apSponsor} />
                    </section>                    
                }
            </section>

        </div>
    );
};

export default APSponsorForm;