// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

import { usePrivy } from '@privy-io/react-auth';

function PrivateRoute({ children }) {
    const { ready, authenticated } = usePrivy();
    return authenticated ? <>{children}</> : <Navigate to="/" />;
}
export default PrivateRoute;