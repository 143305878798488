import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ImageHolder from './ImageHolder';
import ColorPicker from './ColorPicker';
import { usePrivy } from '@privy-io/react-auth';
import ActivityPoolRegistrations from './ActivityPoolRegistrations';
import ActivityPoolRewardsList from './ActivityPoolRewards';

const ActivityPoolForm = () => {
    const { activityPoolId, sponsorId } = useParams();
    const navigate = useNavigate();
    const dt = new Date();
    const [activityPoolImageUrl, setActivityPoolImageUrl] = useState();
    const [sexSettings, setSexSettings] = useState([]);
    const [genderSettings, setGenderSettings] = useState([]);
    const [countries, setCountries] = useState([]);
    const [currencies, setCurrencies] = useState([]);
    const [profileModes, setProfileModes] = useState([]);
    const { user } = usePrivy();
    const [currentUser, setCurrentUser] = useState();
    const [tabIndex, setTabIndex] = useState(0);
    const { hash } = useLocation();

    const [activityPool, setActivityPool] = useState({
        activityPoolStatus: 'DRAFT',
        authorUserId: user.id,
        mainColor: '',
        secondaryColor: '',
        backgroundImage: '',
        iconImage: '',
        title: '',
        description: '',
        instructions: '',
        currencyCode: '',
        entryFee: 0,
        publicVisibleStartDateTime: '0000-00-00T00:00:00',
        entriesOpenStartDateTime: '0000-00-00T00:00:00',
        featuredStartDateTime: '2024-01-01T00:00:00',
        featuredEndDateTime: '2024-01-01T00:00:00',
        entrystartDateTimeFee: '0000-00-00T00:00:00',
        endDateTime: '0000-00-00T00:00:00',
        minimumAge: '0',
        minParticipants: '',
        maxParticipants: '',
        cheatDays: '',
        countryCodeRestriction: '',
        zipCodeRestriction: '',
        townRestriction: '',
        sexRestriction: '',
        genderRestriction: '',
        nftEntryTokenCurrencyId: '',
        goalType: '',
        goalDescription: '',
        goal: 0,
        locationId: '',
        entryFeeCurrencyCode: '',
        nftPassportName: '',
        activityPoolId: '',
        sponsorId: sponsorId,
        rewards: [
            {
                reward_type: "PRIZE_POOL",
                amount: 0,
                currency: "",
                description: ""
            }
        ]
    });

    useEffect(() => {
        if (activityPoolId) {
            const fetchActivityPool = async () => {
                const response = await HealthblocksApi.get(`activity-pool-api/activity-pools/${activityPoolId}`);
                setActivityPool(response.data);
            };
            fetchActivityPool();
            if (hash == "activitypools") setTabIndex(1);
        }
        fetchSettings();
    }, [activityPoolId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setActivityPool({ ...activityPool, [name]: value });
    };

    const deleteActivityPool = async (activityPoolId) => {
        await HealthblocksApi.delete(`/activity-pool-api/activity-pools/${activityPoolId}`);
        navigate("-1");
    }

    const fetchSettings = async () => {
        const currencyResponse = await HealthblocksApi.get(`currency-api/currency/`);
        setCurrencies(currencyResponse.data.data);
        const sexResponse = await HealthblocksApi.get(`user-profile-api/metadata/biological-sex`);
        setSexSettings(sexResponse.data);
        const genderResponse = await HealthblocksApi.get(`user-profile-api/metadata/genders`);
        setGenderSettings(genderResponse.data);
        const profileModeResponse = await HealthblocksApi.get(`user-profile-api/metadata/profile-modes`);
        setProfileModes(profileModeResponse.data);
    }

    const setAPMainImageUrl = (url) => {
        console.log("ActivityPoolForm Set Background Image " + url);
        setActivityPool(prevState => ({
            ...prevState,
            ["backgroundImage"]: url
        }));
        setActivityPoolImageUrl(url);
    }

    const setIconImageUrl = (url) => {
        setActivityPool(prevState => ({
            ...prevState,
            ["iconImage"]: url
        }));
    }

    const setMainColor = (color) => {
        setActivityPool(prevState => ({
            ...prevState,
            ["mainColor"]: color
        }));
    }

    const setSecondaryColor = (color) => {
        setActivityPool(prevState => ({
            ...prevState,
            ["secondaryColor"]: color
        }));
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (activityPoolId) {
            await HealthblocksApi.put(`/activity-pool-api/activity-pools/`, activityPool);
        } else {
            await HealthblocksApi.post('/activity-pool-api/activity-pools/', activityPool);
        }
        navigate(-1);
    };

    return (
        <div>
            <div>
                <div class="sm:hidden">
                    <label for="tabs" class="sr-only">Select a tab</label>
                    <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                        <option>Activity Pool Details</option>
                        <option>Registrations</option>
                        <option>Rewards</option>
                    </select>
                </div>
                <div class="hidden sm:block">
                    <div class="border-b border-gray-200">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            <Link onClick={() => setTabIndex(0)} to="#activitypooldetails" class={tabIndex === 0 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Activity Pool Details</Link>
                            <Link onClick={() => setTabIndex(1)} to="#registrations" class={tabIndex === 1 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Registrations</Link>
                            <Link onClick={() => setTabIndex(2)} to="#rewards" class={tabIndex === 2 ? "whitespace-nowrap border-b-2 border-indigo-500 px-1 py-4 text-sm font-medium text-indigo-600" : "whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"}>Rewards</Link>
                        </nav>
                    </div>
                </div>
            </div>

            <section id="content">
                {tabIndex === 0
                    ?
                    <form onSubmit={handleSubmit} class="mt-4">
                        <div class="space-y-12">
                            <div class="border-b border-gray-900/10 pb-12">
                                <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                    <div class="sm:col-span-6 bg-black text-white px-2">
                                        <h2 class="text-base font-semibold leading-7">Activity Pool - ({activityPool.activityPoolId === "" ? 'New' : activityPool.activityPoolId})</h2>
                                    </div>

                                    {
                                        activityPool.sponsorId !== ''
                                            ?
                                            <div class="sm:col-span-6 bg-black text-white px-2">
                                                <h2 class="text-base font-semibold leading-7 text-white-900">Sponsored By {activityPool.sponsorId}</h2>
                                            </div>
                                            :
                                            ''
                                    }


                                    <div class="sm:col-span-1">
                                        <label for="activityPoolStatus" class="block text-sm font-medium leading-6 text-gray-900">Status</label>
                                        <div class="mt-2">
                                            <select id="activityPoolStatus" name="activityPoolStatus" value={activityPool.activityPoolStatus} onChange={handleChange} placeholder="Status" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option></option>
                                                <option>DRAFT</option>
                                                <option>REVIEW</option>
                                                <option>PUBLISHED</option>
                                                <option>OPEN</option>
                                                <option>ACTIVE</option>
                                                <option>FINISHED</option>
                                                <option>COMPLETED</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="title" class="block text-sm font-medium leading-6 text-gray-900">Title</label>
                                        <div class="mt-2">
                                            <input type="text" name="title" value={activityPool.title} onChange={handleChange} placeholder="title" required id="title" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-6">
                                        <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                                        <div class="mt-2">
                                            <textarea type="text" rows="5" name="description" value={activityPool.description} onChange={handleChange} placeholder="description" required id="description" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></textarea>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="goal" class="block text-sm font-medium leading-6 text-gray-900">Goal</label>
                                        <div class="mt-2">
                                            <input type="text" name="goal" value={activityPool.goal} onChange={handleChange} placeholder="Goal" required id="goal" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="goalType" class="block text-sm font-medium leading-6 text-gray-900">Goal Type (Daily)</label>
                                        <div class="mt-2">
                                            <select id="goalType" name="goalType" value={activityPool.goalType} onChange={handleChange} placeholder="Goal Type" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option></option>
                                                <option>BREATHING</option>
                                                <option>RUNNING</option>
                                                <option>STEPS</option>
                                                <option>WALKING</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="cheatDays" class="block text-sm font-medium leading-6 text-gray-900">Cheat Days</label>
                                        <div class="mt-2">
                                            <input type="text" name="cheatDays" value={activityPool.cheatDays} onChange={handleChange} placeholder="Cheat Days" required id="cheatDays" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="entryFee" class="block text-sm font-medium leading-6 text-gray-900">Entry Fee</label>
                                        <div class="mt-2">
                                            <input type="text" name="entryFee" value={activityPool.entryFee} onChange={handleChange} placeholder="Entry Fee" required id="entryFee" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="currencyCode" class="block text-sm font-medium leading-6 text-gray-900">Currency</label>
                                        <div class="mt-2">
                                            <select id="currencyCode" name="currencyCode" value={activityPool.currencyCode} onChange={handleChange} placeholder="Currency" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option></option>
                                                {currencies
                                                    .map(currency => (
                                                        <option>{currency.currencyCode}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-6">
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="minParticipants" class="block text-sm font-medium leading-6 text-gray-900">Min Participants</label>
                                        <div class="mt-2">
                                            <input type="text" name="minParticipants" value={activityPool.minParticipants} onChange={handleChange} placeholder="Min Participants" required id="minParticipants" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="maxParticipants" class="block text-sm font-medium leading-6 text-gray-900">Max Participants</label>
                                        <div class="mt-2">
                                            <input type="text" name="maxParticipants" value={activityPool.maxParticipants} onChange={handleChange} placeholder="Max Participants" required id="maxParticipants" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-6 bg-black text-white px-2">
                                        <h2 class="text-base font-semibold leading-7 text-white-900">Dates</h2>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="publicVisibleStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Publicly Visible</label>
                                        <div class="mt-2">
                                            <input type="datetime-local" name="publicVisibleStartDateTime" value={activityPool.publicVisibleStartDateTime ? activityPool.publicVisibleStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Public Visible" required id="entriesOpenStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="entriesOpenStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Entries Open</label>
                                        <div class="mt-2">
                                            <input type="datetime-local" name="entriesOpenStartDateTime" value={activityPool.entriesOpenStartDateTime ? activityPool.entriesOpenStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Entries Open" required id="entriesOpenStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="startDateTime" class="block text-sm font-medium leading-6 text-gray-900">Start Date</label>
                                        <div class="mt-2">
                                            <input type="datetime-local" name="startDateTime" value={activityPool.startDateTime ? activityPool.startDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Start Date" id="startDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="endDateTime" class="block text-sm font-medium leading-6 text-gray-900">End Date</label>
                                        <div class="mt-2">
                                            <input type="datetime-local" name="endDateTime" value={activityPool.endDateTime ? activityPool.endDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="End Date" id="endDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-6 bg-black text-white px-2">
                                        <h2 class="text-base font-semibold leading-7 text-white-900">Feature Activity Pool</h2>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="featuredStartDateTime" class="block text-sm font-medium leading-6 text-gray-900">Featured From</label>
                                        <div class="mt-2">
                                            <input type="datetime-local" name="featuredStartDateTime" value={activityPool.featuredStartDateTime ? activityPool.featuredStartDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Featured From" required id="featuredStartDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="featuredEndDateTime" class="block text-sm font-medium leading-6 text-gray-900">Featured To</label>
                                        <div class="mt-2">
                                            <input type="datetime-local" name="featuredEndDateTime" value={activityPool.featuredEndDateTime ? activityPool.featuredEndDateTime.substring(0, 16) : ""} onChange={handleChange} placeholder="Featured To" required id="featuredEndDateTime" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-6 bg-black text-white px-2">
                                        <h2 class="text-base font-semibold leading-7 text-white-900">Entry Restrictions</h2>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="minimumAge" class="block text-sm font-medium leading-6 text-gray-900">Minimum Age</label>
                                        <div class="mt-2">
                                            <input type="text" name="minimumAge" value={activityPool.minimumAge} onChange={handleChange} placeholder="Minimum Age" id="minimumAge" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="townRestriction" class="block text-sm font-medium leading-6 text-gray-900">Town Restriction</label>
                                        <div class="mt-2">
                                            <input type="text" name="townRestriction" value={activityPool.townRestriction} onChange={handleChange} placeholder="Town Restriction" id="townRestriction" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="zipCodeRestriction" class="block text-sm font-medium leading-6 text-gray-900">Zipcode Restriction</label>
                                        <div class="mt-2">
                                            <input type="text" name="zipCodeRestriction" value={activityPool.zipCodeRestriction} onChange={handleChange} placeholder="Zip/Postcode Restriction" id="zipCodeRestriction" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="sexRestriction" class="block text-sm font-medium leading-6 text-gray-900">Sex Restriction</label>
                                        <div class="mt-2">
                                            <select id="sexRestriction" name="sexRestriction" value={activityPool.sexRestriction} onChange={handleChange} placeholder="Sex Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option>NONE</option>
                                                {sexSettings
                                                    .map(sexSetting => (
                                                        <option>{sexSetting}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="genderRestriction" class="block text-sm font-medium leading-6 text-gray-900">Gender Restriction</label>
                                        <div class="mt-2">
                                            <select id="genderRestriction" name="genderRestriction" value={activityPool.genderRestriction} onChange={handleChange} placeholder="Gender Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option>NONE</option>
                                                {genderSettings
                                                    .map(genderSetting => (
                                                        <option>{genderSetting}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-1">
                                        <label for="countryCodeRestriction" class="block text-sm font-medium leading-6 text-gray-900">Country Restriction</label>
                                        <div class="mt-2">
                                            <select id="countryCodeRestriction" name="countryCodeRestriction" value={activityPool.countryCodeRestriction} onChange={handleChange} placeholder="Country Restriction" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6">
                                                <option>NONE</option>
                                                {countries
                                                    .map(country => (
                                                        <option>{country}</option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-6 bg-black text-white px-2">
                                        <h2 class="text-base font-semibold leading-7 text-white-900">Colours and Images</h2>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <ImageHolder imagetitle="Activity Pool Main Image" setImageUrl={setAPMainImageUrl} imageUrl={activityPool.backgroundImage} />
                                    </div>

                                    <div class="sm:col-span-3">
                                        <ImageHolder imagetitle="Activity Pool Icon" setImageUrl={setIconImageUrl} imageUrl={activityPool.iconImage} />
                                    </div>

                                    <div class="sm:col-span-2">
                                        <label for="mainColor" class="block text-sm font-medium leading-6 text-gray-900">Main Colour</label>
                                        <div class="mt-2">
                                            <input type="text" name="mainColor" value={activityPool.mainColor} onChange={handleChange} placeholder="Main Color" id="mainColor" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                        <div class="mt-2">
                                            <ColorPicker color={activityPool.mainColor} setColor={setMainColor} />
                                        </div>
                                    </div>

                                    <div class="sm:col-span-2">
                                        <label for="secondaryColor" class="block text-sm font-medium leading-6 text-gray-900">Secondary Colour</label>
                                        <div class="mt-2">
                                            <input type="text" name="secondaryColor" value={activityPool.secondaryColor} onChange={handleChange} placeholder="Secondary Color" id="secondaryColor" class="p-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"></input>
                                        </div>
                                        <div class="mt-2">
                                            <ColorPicker color={activityPool.secondaryColor} setColor={setSecondaryColor} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="mt-6 flex items-center justify-end gap-x-6">
                            <Link onClick={() => navigate(-1)} class="text-sm font-semibold leading-6 text-gray-900">Cancel</Link>
                            <button type="submit" class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                        </div>
                    </form>
                    :
                    tabIndex === 1
                        ?
                        <section id="activityPoolList">
                            <ActivityPoolRegistrations activityPoolId={activityPool.activityPoolId} />
                        </section>
                        :
                        <ActivityPoolRewardsList />
                }
            </section>

        </div>
    );
};

export default ActivityPoolForm;