import React, { useEffect, useState } from 'react';
import HealthblocksApi from './AxiosConfig';
import { Link } from 'react-router-dom';

const APSponsorList = () => {
    const [apSponsors, setapSponsors] = useState([]);

    const fetchapSponsors = async () => {
        const response = await HealthblocksApi.get('activity-pool-sponsor-api/activity-pool-sponsor/');
        setapSponsors(response.data.data);
    };

    const deleteApSponsor = async (id) => {
        await HealthblocksApi.delete(`/activity-pool-sponsor-api/activity-pool-sponsor/${id}`);
        fetchapSponsors();
    };

    useEffect(() => {
        fetchapSponsors();
    }, []);

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{apSponsors.length} Activity Pool Sponsors</h1>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Link class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="/apSponsors/new">Create New AP Sponsor</Link>
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8">
                                        <span class="sr-only">Edit</span>
                                    </th>                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Name</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Contact</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900 hidden lg:table-cell">Email</th>
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0 hidden lg:table-cell">Active</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {apSponsors
                                    .map(apSponsor => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="relative whitespace-nowrap border-b border-gray-200 py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-8 lg:pr-8">
                                                <Link class="text-indigo-600 hover:text-indigo-900" to={`/apsponsors/${apSponsor.sponsorId}`}>Edit</Link>
                                            </td>
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{apSponsor.name}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{apSponsor.contactName}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500 hidden lg:table-cell">{apSponsor.contactEmail}</td>
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0 hidden lg:table-cell">{apSponsor.isActive ? "Yes" : "No"}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default APSponsorList;