import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import HealthblocksApi from './AxiosConfig';
import { end } from '@cloudinary/url-gen/qualifiers/textAlignment';

const api = axios.create();

const HealthDataDiagnosticsList = (props) => {
    const [healthdata, setHealthData] = useState([]);

    const fetchHealthData = async () => {
        try {
            let config = {
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                }
              }
            var endDate = moment().format("YYYY-MM-DD");
            var startDate = moment().subtract(7,'d').format("YYYY-MM-DD")
            var path = `https://080f-2a00-23c8-4906-b01-f1df-debc-a154-7f30.ngrok-free.app/health-stats?start_date=${startDate}&end_date=${endDate}&user_id=${props.userId}`;
            const response = await axios.get(path,config);
            if(response.data && Array.isArray(response.data)) setHealthData(response.data);
            else {
                console.log(response.data);
            }
        }
        catch(err) {
            console.log(`Error Fetching Diagnostic Health Data Id for ${props.userId}`);
        }
    };

    useEffect(() => {
        fetchHealthData();
    }, []);

    //Export User Profiles
   // const exportprofiles = async () => {
   //     await axios.delete(`https://5b2c-2a00-23c8-4906-b01-bd7b-6f57-c41c-326e.ngrok-free.app/userprofiles`);
   //     var response = await HealthblocksApi.get(`/user-profile-api/user-profile/`);
   //    var profiles = response.data.data;
   //     await Promise.all(profiles.map(async profile => {
   //         var response = await axios.post(`https://5b2c-2a00-23c8-4906-b01-bd7b-6f57-c41c-326e.ngrok-free.app/userprofiles`, profile);
   //     }));
   // };

    return (
        <div class="px-4 sm:px-6 lg:px-8 mt-4">
            <div class="sm:flex sm:items-center">
                <div class="sm:flex-auto">
                    <h1 class="text-base font-semibold leading-6 text-gray-900">{healthdata.length} WeFitter Health Records</h1>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <Link onClick={() => {fetchHealthData()}} class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" to="">Refresh</Link>
                </div>
                <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                </div>
            </div>
            <div class="mt-8 flow-root">
                <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr class="divide-x divide-gray-200">
                                    <th scope="col" class="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">Date</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Steps</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Apple Steps</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Calories</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Run Distance</th>
                                    <th scope="col" class="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">Cycle Distance</th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                {healthdata
                                    .map(healthDataRecord => (
                                        <tr class="divide-x divide-gray-200">
                                            <td class="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">{moment(healthDataRecord.activity_date).format("DD-MM-YYYY")}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.steps}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.apple_steps}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.calories}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.run_distance}</td>
                                            <td class="whitespace-nowrap p-4 text-sm text-gray-500">{healthDataRecord.cycle_distance}</td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HealthDataDiagnosticsList;